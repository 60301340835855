import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";



const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/Register'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const GoogleConfirm = lazy(() => import('./pages/GoogleConfirm'))

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path='/google-confirm' component={GoogleConfirm} />
            <Route exact path="/create-account" component={CreateAccount} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />

            {/* Place new routes over this */}
            <ProtectedRoute path="/" component={Layout} />
            {/* If you have an index page, you can remove this Redirect */}
            {/* <Redirect exact from="/" to="/login" /> */}
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
